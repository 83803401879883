//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  dashboard: {
    nav: {
      dashboard: 'Dashboard',
    },
    logoutCTA: 'Ausloggen',
    loginCTA: 'Einloggen',
  },

  header: {
    backToHome: 'zurück',
    menu: {
      home: {
        name: 'Startseite',
        href: '/',
      },
      services: {
        name: 'Leistungen',
        href: '/#leistungen',
      },
      team: {
        name: 'Team',
        href: '/team',
      },
      office: {
        name: 'Praxis',
        href: '/#praxis',
      },
      contact: {
        name: 'Kontakt',
        href: '/#kontakt',
      },
      imprint: {
        name: 'Impressum',
        href: '/impressum',
      },
      privacy: {
        name: 'Datenschutz',
        href: '/datenschutz',
      },
    },
  },

  home: {
    hero: {
      banner: {
        text: 'Wir suchen tatkräftige Unterstützung für unser Team',
      },
      timesHeadline: 'Öffnungszeiten:',
      timesColumns: [
        ['Mo:', 'Di:', 'Mi:', 'Do:', 'Fr:'],
        ['08:00 - 19:00 Uhr', '08:00 - 19:00 Uhr', '08:00 - 12:00 Uhr', '08:00 - 12:00 Uhr', '08:00 - 12:00 Uhr'],
        [null, null, null, '14:00 - 18:00 Uhr', null],
      ],
      timeHint:
        'Die Öffnungszeiten können in den Schulferien variieren. Bitte melden Sie sich vor Ihrem Besuch telefonisch an, um sicherzustellen, dass wir für Sie da sind.',
    },
    services: {
      headline: 'Unsere Leistungen',
      items: [
        {
          name: 'Prophylaxe',
          href: '/prophylaxe',
        },
        {
          name: 'Prothetik',
          href: '/prothetik',
        },
        {
          name: 'Ästethische Zahnmedizin',
          href: '/aesthetische-zahnmedizin',
        },
        {
          name: 'Implantologie',
          href: '/implantologie',
        },
      ],
    },
    instagram: {
      headline: 'Verfolgen Sie unseren Alltag auf Instagram',
      handle: '@zahnarztpraxis_eisberg',
    },
    team: {
      headline: 'Unser Team',
      text: 'Unsere Zahnarztpraxis in Oer-Erkenschwick bietet Ihnen eine große Bandbreite der modernen Zahnmedizin. Seit nun mehr als 20 Jahren sind wir für unsere Patienten und Patientinnen im Einsatz. Unser Ziel ist es, dass Sie sich bei uns wohl fühlen und wir ihnen die bestmögliche Versorgung zusichern. Dazu gehört ein freundlicher Umgang innerhalb des Teams und ein offenes Ohr für alle Patienten.',
    },
    office: {
      headline: 'Unsere Praxis',
      equipment: 'Ausstattung',
      furnishing: 'Einrichtung',
      tour: 'Rundgang',
    },
    contact: {
      headline: 'Kontakt',
      fax: 'Fax-Nr.:',
      form: {
        submit: 'senden',
        success: 'Ihre Nachricht wurde erfolgreich versendet.',
        error: 'Ihre Nachricht konnte nicht versendet werden.',
      },
    },
  },

  equipment: {
    headline: 'Ausstattung',
    hint: 'Unsere Praxis arbeitet weitgehend digital und ist mit den neusten Geräten ausgestattet.',
  },
  office: {
    headline: 'Einrichtung',
    hint: 'Unsere Praxis ist barrierfrei zugänglich und schafft eine angenehme Atmosphäre.',
  },

  tour: {
    headline: 'Rundgang',
    hint: 'Besuchen sie unsere Praxis digital',
  },

  services: {
    prophylaxe: {
      headline: 'Prophylaxe',
      boxes: [
        {
          title: 'PZR (professionelle Zahnreinigung)',
          text: [
            'Prophylaxe ist ein wichtiger Teil Ihrer oralen Gesundheit und kann dazu beitragen, Zahnprobleme und Karies vorzubeugen. Es geht darum, Ihre Zähne und Ihr Zahnfleisch zu schützen und zu pflegen, damit Sie lange Zeit ein gesundes Lächeln behalten.',
            'Eine wichtige Maßnahme der Prophylaxe ist die regelmäßige Zahnreinigung beim Zahnarzt. Hierbei werden Plaque und Zahnbeläge entfernt, um Karies und Parodontitis zu verhindern. Eine professionelle Zahnreinigung kann auch dazu beitragen, dass Ihr Atem frischer riecht und Ihre Zähne glänzen.',
          ],
        },
        {
          title: 'Individualprophylaxe von klein auf',
          text: [
            'Ein weiterer wichtiger Teil der Prophylaxe ist eine gesunde Mundhygiene zu Hause. Dazu gehört das regelmäßige Zähneputzen, das Verwenden von Zahnseide und das Spülen mit einer antibakteriellen Lösung. Es ist auch wichtig, auf eine ausgewogene Ernährung zu achten, die reich an Vitaminen und Mineralstoffen ist und den Konsum von zuckerhaltigen Lebensmitteln und Getränken zu minimieren.',
            'Wenn Sie Prophylaxe ernst nehmen, können Sie dazu beitragen, dass Ihre Zähne und Ihr Zahnfleisch gesund bleiben. Reden Sie mit Ihrem Zahnmediziner über die besten Prophylaxemaßnahmen für Sie und halten Sie Ihr Lächeln gesund!',
          ],
        },
      ],
    },
    prothetik: {
      headline: 'Prothetik',
      boxes: [
        {
          title: 'Individualprophylaxe von klein auf',
          text: [
            'Wenn Sie einen oder mehrere fehlende oder beschädigte Zähne haben, kann Ihnen die Prothetik in der Zahnmedizin helfen, wieder ein gesundes und funktionsfähiges Gebiss zu erlangen. Die Prothetik ist ein Teilbereich der Zahnmedizin, der sich mit der Herstellung, dem Einbau und der Reparatur von künstlichen Zähnen und Zahnprothesen beschäftigt, wie Teilprothesen, Vollprothesen, Krönchen und Brücken.',
            'Als Patient ist es wichtig zu wissen, dass Ihnen durch die Prothetik eine möglichst natürliche Funktion und Ästhetik zurückgegeben werden kann. Dies bedeutet, dass Sie wieder ohne Schmerzen oder Einschränkungen essen und sprechen können und dass Ihr Lächeln wieder vollständig aussieht. Ihr Zahnmediziner wird mit Ihnen zusammenarbeiten, um sicherzustellen, dass die für Sie beste Lösung gefunden wird.',
          ],
        },
      ],
    },
    aestethischeZahnmedizin: {
      headline: 'Ästethische Zahnmedizin',
      boxes: [
        {
          title: 'In-office-Bleaching',
          text: [
            'Ästhetische Zahnmedizin konzentriert sich auf die Verbesserung des Aussehens Ihrer Zähne und Ihres Lächelns. Dies kann dazu beitragen, Ihr Selbstbewusstsein zu stärken und Ihnen mehr Freude am Lachen und Sprechen zu geben.',
            'Eine der häufigsten Methoden der ästhetischen Zahnmedizin ist die Verwendung von Zahnaufhellungsbehandlungen, um Verfärbungen und Flecken zu entfernen. Es gibt auch eine Vielzahl von Verfahren zur Veränderung der Form, Größe und Farbe Ihrer Zähne, wie zum Beispiel Veneers oder direkte kosmetische Zahnfüllungen.',
          ],
        },
        {
          title: 'Standard',
          text: [
            'Für eine noch gründlichere Transformation kann auch eine Kombination aus verschiedenen Verfahren in Betracht gezogen werden. Zum Beispiel kann eine Zahnaufhellung in Verbindung mit kosmetischen Zahnfüllungen oder Veneers zu einem ganz neuen, strahlenden Lächeln führen.',
            'Es ist wichtig zu beachten, dass ästhetische Zahnmedizin nicht nur ästhetische Vorteile hat, sondern auch die Funktionalität und Gesundheit Ihrer Zähne verbessert. Ein gesundes, ästhetisches Lächeln kann Ihr Selbstbewusstsein stärken und Ihnen mehr Freude am Leben geben. Sprechen Sie mit Ihrem Zahnmediziner über Ihre ästhetischen Zahnmedizinoptionen und machen Sie den ersten Schritt zu einem strahlenderen Lächeln.',
          ],
        },
      ],
    },
    implantologie: {
      headline: 'Implantologie',
      boxes: [
        {
          title: 'Miniimplantate zur Prothesenstabilisation',
          text: [
            'Implantologie ist ein Teilbereich der Zahnmedizin, der sich mit der Behandlung von fehlenden Zähnen beschäftigt. Hierbei werden künstliche Zahnwurzeln in den Kieferknochen eingesetzt, um fehlende Zähne zu ersetzen. Diese künstlichen Wurzeln dienen als Träger für Kronen, Brücken oder Prothesen und können Ihnen helfen, Ihre natürliche Kaufähigkeit wiederherzustellen und Ihr Lächeln zu verbessern.',
            'Implantate sind eine sichere und langlebige Lösung für fehlende Zähne. Sie sehen aus und fühlen sich wie natürliche Zähne an und können bis zu 25 Jahre oder länger halten, wenn sie ordnungsgemäß gepflegt werden.',
          ],
        },
        {
          title: 'Standard',
          text: [
            'Die Implantologie-Behandlung kann in mehreren Schritten erfolgen, einschließlich einer gründlichen Prüfung Ihrer Mundgesundheit, der Implantat- Einpflanzung und der Herstellung von Kronen, Brücken oder Prothesen, die auf den Implantaten befestigt werden. Die Behandlungsdauer kann von einigen Monaten bis zu einem Jahr oder mehr dauern, je nach dem Umfang und der Komplexität Ihres spezifischen Behandlungsplans.',
            'Wenn Sie unter fehlenden Zähnen leiden und eine langlebige, ästhetisch ansprechende Lösung suchen, kann die Implantologie die richtige Wahl für Sie sein. Sprechen Sie mit Ihrem Zahnmediziner über Ihre Optionen und machen Sie den ersten Schritt zu einem vollständigen, gesunden Lächeln.',
          ],
        },
      ],
    },
  },

  team: {
    headline: 'Team',
    lead: [
      {
        name: 'Claudia Eisberg',
        position: 'Zahnärztin',
      },
      { name: 'Dr. Juliane Oeldemann', position: 'Zahnärztin' },
    ],
    members: [
      { name: 'Nicole Falk', position: 'Verwaltung' },
      { name: 'Nicole Reiß', position: 'Verwaltung' },
      { name: 'Gabriele Maaß', position: 'Prophylaxe' },
      { name: 'Nicole Szmania', position: 'Prophylaxe' },
      { name: 'Anjesa Shala', position: 'Assistenz' },
      { name: 'Anja Prebeck', position: 'Assistenz' },
    ],
  },

  jobs: {
    headline: 'Unsere offenen Stellenangebote',
    title: 'ZAHNMEDIZINISCHE FACHASSISTENZ (ZFA) M/W/D',
    aboutText:
      'Wir sind eine kleine, familiäre und hochmoderne Praxis in Oer-Erckenschwick mit zwei Ärztinnen und suchen Verstärkung in Vollzeit. Abgedeckt wird die ganze Zahnheilkunde. Wir sorgen für regelmäßige Weiterentwicklung durch Fortbildungen, Bildungsurlaub und Karrieregespräch. Uns ist Zusammenarbeit im Team wichtig. Jeder soll sich wohlfühlen!',
    offeringsHeadline: 'Als Teil unseres Teams wirst du...',
    offerings: [
      '... gemeinsam mit unseren Zahnärzten für die beste und angenehmste Behandlung unserer Patienten und Patientinnen sorgen',
      '... selbstständig die Vor- und Nachbereitung der Behandlungen übernehmen',
      '... unsere Standards für Qualität und Hygiene hoch halten',
      '... selbstständig Aufgaben in deinem Fachbereich übernehmen',
    ],
    expectationsHeadline: 'Folgendes solltest du mitbringen:',
    expectations: [
      '- Relevante Berufserfahrung',
      '- Empathie ist dein Markenzeichen',
      '- Lust, gemeinsam mit uns den Praxisalltag zu gestalten',
      '- Motivation, strukturiert und organisiert zu arbeiten',
      '- Berufliche und persönliche Weiterentwicklung sind dir wichtig',
    ],
    offering: {
      headline: 'Wir bieten dir',
      items: ['... ein modernes Arbeitsumfeld', '... ein aufgeschlossenes Team', '... flexible Arbeitszeiten'],
    },
    form: {
      headline: 'Schnelle und einfache Bewerbung über unser Berwerbungsformular',
      messagePlaceholder: 'Bewerbungsanschreiben',
      cv: 'Lebenslauf',
      cv_error: 'Bitte füge deinen Lebenslauf hinzu.',
      max_size_error: 'Die Datei ist zu groß. Bitte wähle eine PDF-Datei mit einer Größe von maximal 50 MB.',
      success: 'Vielen Dank für deine Bewerbung. Wir werden uns schnellstmöglich bei dir melden.',
      error: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.',
    },
  },

  footer: {
    aboutUs: {
      title: 'Über uns',
      text: [
        'Die Zahnarztpraxis Eisberg in Oer-Erkenschwick ist eine moderne und freundliche Praxis, die Ihnen eine breite Palette an zahnmedizinischen Dienstleistungen anbietet. Unser kompetentes Team aus erfahrenen Zahnmedizinern und freundlichem Personal steht Ihnen zur Verfügung, um Ihnen eine angenehme Behandlungserfahrung zu bieten.',
      ],
    },
    contact: 'Kontakt',
    pages: {
      title: 'Seiten',
    },
  },

  copy: {
    cta: 'Kopieren',
    success: 'Kopiert',
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  genericValues: {
    name: 'Zahnarztpraxis Claudia Eisberg',
    mail: 'praxis.eisberg@t-online.de',
    phone: '02368/1516',
    fax: '02368/692986',
    street: 'Fritz-Reuter-Str. 38',
    city: '45739 Oer-Erkenschwick',
    instagramHandle: '@zahnarztpraxis.eisberg',
    instagramLink: 'https://www.instagram.com/zahnarztpraxis.eisberg',
    facebookHandle: '@zahnarztpraxis.eisberg',
    facebookLink: 'https://www.facebook.com/eisberg.zahnarztpraxis/',
    locationLink:
      'https://www.google.com/maps/place/Zahnarztpraxis+Claudia+Eisberg/@51.6465556,7.2433085,17z/data=!4m13!1m7!3m6!1s0x47b8fd23377e1c5f:0xe29b2629261d70d!2sFritz-Reuter-Stra%C3%9Fe+38,+45739+Oer-Erkenschwick!3b1!8m2!3d51.6465556!4d7.2433085!3m4!1s0x47b8fd233efd36e7:0xc802821f8abe264a!8m2!3d51.6464465!4d7.2432826',
  },

  generic: {
    name: 'Name',
    message: 'Nachricht',
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Passwort',
    subject: 'Betreff',
    save: 'Speichern',
  },

  login: {
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
